// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-detail-template-js": () => import("./../../../src/templates/blog-detail-template.js" /* webpackChunkName: "component---src-templates-blog-detail-template-js" */),
  "component---src-templates-gobig-template-js": () => import("./../../../src/templates/gobig-template.js" /* webpackChunkName: "component---src-templates-gobig-template-js" */),
  "component---src-templates-landing-page-template-js": () => import("./../../../src/templates/landing-page-template.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-rich-text-template-js": () => import("./../../../src/templates/rich-text-template.js" /* webpackChunkName: "component---src-templates-rich-text-template-js" */)
}

